<template>
	<v-app>
		<v-row v-if="intBreakPoint >= 2 &&
			!isLp
			" style="
				padding: 0px 60px !important;
				height: 100px;
				background: linear-gradient( 177deg, rgba(27, 108, 171, 1) 5%, rgba(21, 75, 117, 1) 67% ); ">
			<v-col cols="12" md="4" class="align-center d-flex" style="padding-bottom: 0px !important">
				<div class="d-flex align-center">
					<router-link to="/">
						<v-img alt="Logo Pró Clínica" class="shrink mt-1 mobileSize sombra" contain :src="end.logoBranco"
							max-width="220" style="left: 20px" />
					</router-link>
				</div>
			</v-col>
			<v-col cols="12" md="8" class="align-center d-flex" style="justify-content: right">
				<router-link v-for="(item, i) in itemsENG" :key="i" :to="{ name: item.path }" style="
						text-decoration: none !important;
						padding-left: 0px;
						padding-right: 0px;
					">
					<v-btn class="btnMenu" style="
							font-family: poppins, sans-serif !important;
							color: #fff !important;
							font-size: 16px;
							font-weight: 500 !important;
						">
						{{ item.name }}
					</v-btn>
				</router-link>
			</v-col>
		</v-row>

		<v-app-bar v-if="intBreakPoint < 2 && !isLp"
			style="background: linear-gradient( 177deg, rgba(27, 108, 171, 1) 5%, rgba(21, 75, 117, 1) 67% ); ">
			<v-row>
				<v-col cols="6" md="6" class="align-center justify-center d-flex" style="">
					<div class="d-flex align-center">
						<router-link to="/">
							<v-img alt="Logo Pró Clínica" class="shrink mt-1 mobileSize sombra" contain :src="end.logoBranco"
								max-width="150" style="left: 10px" />
						</router-link>
					</div>
				</v-col>
				<v-col cols="6" md="6" class="align-center justify-center d-flex" style="">
					<v-icon style="
							color: #fff;
							margin-left: 0px !important;
							background: linear-gradient(
								177deg,
								rgba(27, 108, 171, 1) 5%,
								rgba(21, 75, 117, 1) 67%
							);
						" v-if="intBreakPoint < 2" @click.stop="drawer = !drawer">
						mdi-menu
					</v-icon>
				</v-col>
			</v-row>
		</v-app-bar>

		<!-- <v-app-bar
      app
      class="align-center justify-center d-flex corMobile"
      dark
      style="padding: 4px 0px !important; height: 100px;
      background: linear-gradient(177deg, rgba(27,108,171,1) 5%, rgba(21,75,117,1) 67%);">
        <v-row v-if="intBreakPoint >= 2">
          <v-col cols="12" md="4" class="" style="">
            <div class="d-flex align-center">
              <router-link to="/">
                <v-img
                  alt="Logo Pró Clínica"
                  class="shrink mt-1 mobileSize sombra"
                  contain
                  :src="end.logoBranco"
                  max-width="220"
                  style="left: -220px;"
                />
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="8" class="align-center d-flex" style="">
            <router-link
              v-for="(item, i) in itemsENG"
              :key="i"
              :to="{ name: item.path }"
              style="
                text-decoration: none !important;
                padding-left: 0px;
                padding-right: 0px;">
              <v-btn
                class="btnMenu"
                style="
                  font-family: poppins, sans-serif !important;
                  left: 300px;
                  color: #fff !important;
                  font-size: 16px;
                  font-weight: 500 !important;">
                {{ item.name }}
                </v-btn>
            </router-link>
          </v-col>
      </v-row>

      <div class="d-flex align-center" v-if="intBreakPoint < 2">
        <router-link to="/">
          <v-img
            alt="Logo Solanute"
            class="shrink mt-1 mobileSize"
            contain
            :src="end.logoBranco"
            max-width="180"
          />
        </router-link>
      </div>

      <v-app-bar-nav-icon
        style="color: #fff; margin-left: 50px !important"
        class="ml-3"
        v-if="intBreakPoint < 2 "
        @click.stop="drawer = !drawer"
      >
      </v-app-bar-nav-icon>

      <v-spacer class="DisableMobile"></v-spacer>
    </v-app-bar> -->

		<v-navigation-drawer v-model="drawer" fixed temporary right style="background-color: #1076bd; opacity: 0.9">
			<v-list dense>
				<v-list-item-group v-model="group" color="primary">
					<router-link v-for="(item, i) in itemsENG" :key="i" :to="{ name: item.path }">
						<v-list-item class="my-10">
							<v-list-item-content>
								<v-list-item-title style="
										text-decoration: none;
										font-family: poppins, sans-serif;
										font-size: 18px;
										color: #fff;
									" v-text="item.name"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</router-link>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<div v-if="intBreakPoint >= 2" class="d-flex flex-column justify-center">
			<button @click="dialog = true" style="z-index: 9999; display: flex; line-height: 34px" color="inherit"
				class="btnFloat">
				<svg-icon type="mdi" :path="this.mdiWhatsapp" style="height: 75px; width: 75px"></svg-icon>
				<span style="
						font-size: 22px;
						font-family: poppins, sans-serif;
						font-weight: 500;
						margin-top: 22px;
					">Entre em contato!</span>
			</button>
		</div>

		<v-main style="padding-bottom: 0px !important;">
			<router-view />
		</v-main>

		<v-img v-if="intBreakPoint < 2" @click="dialog = true" class="whatsMobile" src="./assets/whats.webp" contain
			width="58" height="20 !important" style="position: fixed; bottom: -240px; right: 30px"></v-img>
		<v-footer style="padding: 0px 0px !important" v-if="!isLp">
			<v-img v-if="intBreakPoint < 2" @click="dialog = true" class="whatsMobile" src="./assets/whats.webp" contain
				width="58" height="20 !important" style="position: fixed; bottom: -240px; right: 30px"></v-img>
			<v-row style="
					background-color: #6eb5e1;
					height: auto;
					padding-bottom: 0px;
					margin: 0px !important;
				" class=" align-center justify-center d-flex">
				<v-col cols="12" md="12" class="align-self-start">
					<v-row class="align-center d-flex justify-center">
						<v-col cols="12" md="2" style="padding-left: 20px !important"
							class="justify-center d-flex flex-column align-self-start">
							<p style="
									font-weight: 800;
									font-family: poppins, sans-serif;
									color: #fff;
								">
								Páginas
							</p>
							<router-link to="/">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Início
								</p>
							</router-link>
							<router-link to="/blog">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Blog
								</p>
							</router-link>
							<!-- <router-link to="sobre">
                <p style="font-weight:400;  font-family: poppins, sans-serif; color:#fff">Sobre</p>
              </router-link> -->
							<router-link to="medicina">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Medicina
								</p>
							</router-link>
							<router-link to="odonto">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Odontologia
								</p>
							</router-link>
							<router-link to="exame">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Exames
								</p>
							</router-link>
							<router-link to="contato">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									Contato
								</p>
							</router-link>
							<!-- <router-link to="fiquePorDentro">
                <p style="font-weight:400;  font-family: poppins, sans-serif;">Fique por dentro</p>
              </router-link> -->
						</v-col>

						<v-col cols="12" md="4" style="padding-bottom: 0px"
							class="justify-center d-flex flex-column align-self-start">
							<p style="
									font-weight: 800;
									font-family: poppins, sans-serif;
									color: #fff;
								">
								Contato
							</p>
							<a :href="end.goggle" target="_blank">
								<p style="
										font-weight: 400;
										font-family: poppins, sans-serif;
										color: #fff;
									">
									{{ end.texto }}
								</p>
							</a>
							<a :href="end.telefone">
								<p class="telMb" style="font-weight: 600; color: #fff">
									{{ end.textoTelefone }}
								</p>
							</a>
						</v-col>

						<v-col v-if="intBreakPoint > 2" cols="12" md="6" style="" class="justify-center d-flex">
							<div class="flex-column d-flex align-center justify-center" style="margin-right: 20px">
								<a :href="end.uber" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 16px 20px;
											border-radius: 20px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 50px" src="./assets/uber.webp"></v-img>
									</div>
								</a>

								<a :href="end.waze" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 18px 12px;
											border-radius: 20px;
											margin: 10px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 65px" src="./assets/waze.webp"></v-img>
									</div>
								</a>

								<a :href="end.goggle" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 10px 16px;
											border-radius: 20px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 55px" src="./assets/maps.webp"></v-img>
									</div>
								</a>
							</div>
							<iframe class="wdMapa" :src="end.iframe" width="400" height="300" style="border: 0; border-radius: 20px"
								allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
							</iframe>
						</v-col>
						<!------------------------------------------------------------------------------------- MAPA MOBILE ------------------------------------------------------------------------------------------------>
						<v-col v-if="intBreakPoint < 2" cols="12" md="6" class="justify-center align-center flex-column d-flex">
							<iframe class="wdMapa" :src="end.iframe" width="400" height="300" style="border: 0; border-radius: 20px"
								allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
							</iframe>
							<div class="d-flex align-center justify-center">
								<a :href="end.uber" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 16px 20px;
											border-radius: 20px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 50px" src="./assets/uber.webp"></v-img>
									</div>
								</a>

								<a :href="end.waze" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 17px 12px;
											border-radius: 20px;
											margin: 5px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 65px" src="./assets/waze.webp"></v-img>
									</div>
								</a>

								<a :href="end.goggle" target="_blank">
									<div style="
											background-color: transparent;
											border: 1px solid #fff;
											padding: 10px 16px;
											border-radius: 20px;
										" class="align-center justify-center d-flex">
										<v-img contain style="max-width: 55px" src="./assets/maps.webp"></v-img>
									</div>
								</a>
							</div>
						</v-col>

						<v-col cols="12" md="12" style="padding-left: 20px !important; padding-bottom: 0px"
							class="align-center justify-center">
							<p style="
									font-weight: 400;
									font-family: poppins, sans-serif;
									color: #fff;
								" v-html="end.CRM"></p>
							<p style="
									font-weight: 400;
									font-family: poppins, sans-serif;
									color: #fff;
								" v-html="end.CRO"></p>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-footer>
		<v-footer style="padding: 0px 0px !important" v-if="!isLp">
			<v-row style="margin: 0px">
				<v-col cols="12" md="12" style="padding: 0px !important">
					<v-row style="
							padding: 0px 80px;
							background-color: #0965ab;
							height: 20vh;
							margin: -0px !important;
						" class="padFooter">
						<v-col cols="12" md="6" class="align-center justify-center d-flex">
							<p style="color: #fff; font-weight: 700; font-size: 15px">
								Pró Clínica - Todos direitos reservados. <br />
								Site atualizado em 2022
							</p>
						</v-col>

						<v-col class="align-center justify-center d-flex" cols="12" md="6">
							<a target="_blank" href="https://abaremarketing.com/">
								<v-img contain width="85" src="./assets/abareBranco.webp"></v-img>
							</a>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-footer>
		<v-dialog class="wpp" v-model="dialog" max-width="800">
			<v-card class="bgcDialog" style="
					margin: 0px !important;
					padding: 5px !important;
					height: 100% !important;
				">
				<v-row style="
						background-color: #09564c;
						padding: 20px 20px;
						margin: 10px;
						border-radius: 10px;
					">
					<v-col cols="6" md="6" class="d-flex">
						<v-img contain style="max-width: 200px" class="wdLogo" src="./assets/logoBranco1.webp"></v-img>
					</v-col>
					<v-col cols="6" md="6" class="pdlfBtn" style="padding-left: 320px">
						<v-btn icon @click="dialog = false">
							<!-- <svg-icon type="mdi" :path="mdiClose" style="height: 50px; width: 50px"></svg-icon> -->
							<v-icon color="#fff" size="2rem">mdi-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row style="margin: 10px">
					<v-col cols="12" md="8" style="background-color: #fff; margin: 0px; border-radius: 20px">
						<p style="font-size: 17px; color: 800; margin-bottom: 0px">
							Olá, complete os campos abaixo e entre em contato conosco!
						</p>
					</v-col>
				</v-row>
				<v-row style="margin: 10px">
					<v-col cols="12" md="6"></v-col>
					<v-col cols="12" md="6" class="justify-center d-flex flex-column">
						<form :id="end.form">
							<v-text-field height="50px" background-color="#E7FFE7" placeholder="Nome completo" v-model="contato.nome"
								outlined style="border-radius: 5px"></v-text-field>
							<v-text-field height="50px" background-color="#E7FFE7" v-mask="'(##) #####-####'" placeholder="Whatsapp"
								v-model="contato.telefone" outlined style="border-radius: 5px"></v-text-field>
							<v-btn @click="enviarFormulario" class="btnAtivo" type="submit" :disabled="loading" style="
									background-color: #09564c;
									color: #fff;
									font-weight: 800;
									text-transform: none;
									font-size: 18px;
								">
								Iniciar atendimento
							</v-btn>
						</form>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="timeout">
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
					Fechar
				</v-btn>
			</template>
		</v-snackbar>

		<!-- <v-dialog v-model="popUpNovembro" :width="intBreakPoint >= 2 ? '50vw' : '100vw'">
			<PopUpNovembroAzul @closeDialog="closeDialog" />
		</v-dialog> -->
	</v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
	font-family: 'Poppins', sans-serif;
	src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins', sans-serif;

	src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

/* @font-face {
	font-family: 'Montserrat', sans-serif;

	src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'Montserrat', sans-serif;

	src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: 'Montserrat', sans-serif;

	src: url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
	font-stretch: normal;
} */


.v-container {
	padding: 0 !important;
}

@media (min-device-width: 340px) and (max-device-width: 731px) {
	.wdMapa {
		width: 99%;
	}

	.pdlfBtn {
		padding-left: 90px !important;
	}

	.paddingDialog {
		padding: 0px 25px !important;
	}

	.v-application--is-ltr .offset-2 {
		margin-left: 0% !important;
	}

	.telMb {
		padding-right: 40px !important;
	}

	.padFooter {
		height: auto !important;
	}

	.hgMobile {
		height: auto !important;
	}

	.padLeftMobile {
		padding: 0px 10px !important;
	}

	.marginMobile {
		margin-bottom: 90px !important;
	}

	.corMobile {
		background-color: #ffffff !important;
	}
}

a {
	color: #000 !important;
	/* cor dos links */
	text-decoration: none;
	/* sem sublinhado */
}

.btnFloat {
	position: fixed !important;
	right: -218px;
	border-radius: 50px 0 0 50px !important;
	background-color: #34af23 !important;
	color: #ffffff !important;
	font-family: 'Poppins', sans-serif !important;
	top: 490px !important;
	padding: 15px 20px;
	font-weight: 300 !important;
}

.btnFloat:hover {
	right: -1px !important;
	transition: all 0.3s ease !important;
}

.btnMenu {
	background-color: transparent !important;
	box-shadow: none !important;
	font-family: Open Sans, sans-serif !important;
	font-weight: 400 !important;
	color: #000 !important;
	text-transform: initial !important;
}

.bgcDialog {
	background-image: url('./assets/whatsapp.webp') !important;
	background-repeat: repeat;
	background-size: cover;
	/* background-attachment: fixed; */
	background-position: center;
}

.backgroundDialog {
	background-image: url('./assets/clinica.webp');
	background-repeat: no-repeat !important;
	background-size: cover;
	height: 500px;
	background-attachment: absolute;
	background-color: rgba(255, 255, 255, 0.596);
	background-position: center;
}

.alinhadoM {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.sombra {
	-webkit-filter: drop-shadow(3px 3px 3px rgb(17, 0, 116));
	filter: drop-shadow(2px 2px 2px rgba(26, 27, 27, 0.877));
}

html {
	scroll-behavior: smooth;
}

.v-image {
	height: 100% !important;
}

.hgMobile {
	padding: 0 60px !important;
}

@media (min-device-width: 360px) and (max-device-width: 731px) {
	.alignMobile {
		align-items: center !important;
	}

	.DisableMobile {
		display: none !important;
	}

	.padMobile {
		padding: 50px 20px !important;
	}
}

.alinhadoM {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

a {
	text-decoration: none;
}

/* .v-dialog {
  width: auto !important;
} */

.wpp {
	max-width: 800px !important;
}

.btnMenu {
	font-family: 'Poppins', sans-serif !important;
	font-weight: 300 !important;
	box-shadow: none !important;
	border: none !important;
	background-color: transparent !important;
}

.btnMenu:hover {
	color: #769d4a !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 300 !important;
	box-shadow: none !important;
	border: none !important;
}
</style>

<script>
import { mdiWhatsapp, mdiClose } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default {
	name: 'App',
	components: {
		SvgIcon,
		// PopUpOutubroRosaVue,
		// PopUpNovembroAzul
	},
	data: () => ({
		mdiWhatsapp: mdiWhatsapp,
		mdiClose: mdiClose,
		dialog: false,
		drawer: false,
		snackbar: false,
		loading: false,
		text: 'Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.',
		group: {},
		contato: { nome: '', telefone: '' },
		timeout: 4000,
		itemsENG: [
			{ name: 'Início', path: 'home' },
			{ name: 'Blog', path: 'blog' },
			{ name: 'Medicina', path: 'medicina' },
			{ name: 'Odontologia', path: 'odonto' },
			{ name: 'Exames', path: 'exame' },
			{ name: 'Contato', path: 'contato' },
		],
		popUpNovembro: false,
	}),
	created() {
		this.$store.dispatch('setunidade');
	},
	mounted() {
		let self = this;

		setTimeout(() => {
			self.dialog = false;
		}, 200);

		window.scrollTo(0, 0);

		this.$root.$on('show-whats', () => {
			this.dialog = true;
		});

		setTimeout(() => {
			this.activePopUp();
		}, 500);
	},
	methods: {
		async enviarFormulario(e) {
			e.preventDefault();
			let user = this.contato;
			this.loading = true;

			if (user.nome === '') {
				alert('Por favor preencha seu nome');
				this.loading = false;
				this.snackbar = false;
				return;
			} else if (user.telefone === '') {
				this.loading = false;
				alert('Por favor preencha seu telefone');
				return;
			}
			user.email = this.computedEmail;
			user.origem = this.currentRouteName;
			// eslint-disable-next-line no-debugger
			//debugger;
			const userExists = await this.$store.dispatch("checkUserExists", user);

      if (userExists) {
        this.snackbar = true;
        this.dialog = false;
        this.abrirWhatsapp();
        this.abrirObrigado();
      } else {
        this.$store.dispatch("enviarFormulario", user);

        this.$store.dispatch("enviaRdstation", user).then((dt) => {
          if (dt) {
            this.loading = false;
            this.contato = {
              nome: "",
              email: "",
              telefone: "",
              assunto: "",
              texto: "",
            };
            this.snackbar = true;
            this.dialog = false;
            this.abrirWhatsapp();
            this.abrirObrigado();
          }
        });
      }
		},
		abrirObrigado() {
			console.log(this.$route.name == 'home')
			this.$router.push(
				`${this.$route.name == 'home'
					? ''
					: this.$route.name
				}/obrigado`
			);
			setTimeout(() => { window.location.reload() }, 500)
		},
		abrirWhatsapp() {
			window.open(this.end.whats);
		},
		closeDialog() {
			this.popUpNovembro = false;
		},
		activePopUp() {
			switch (this.$route.name) {
				case 'home':
					return this.popUpNovembro = true;
				case 'urologia':
					return this.popUpNovembro = true;
			}

			if (this.popUpNovembro == false) {
				return false;
			}

			return false;
		},
	},

	computed: {
		currentRouteName() {
			return this.$route.name;
		},
		computedEmail() {
			return `${this.contato.telefone
				.replace('-', '')
				.replace('(', '')
				.replace(')', '')
				.replaceAll(' ', '')}@${this.contato.nome
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replaceAll(' ', '')
					.toLowerCase()}.com.br`;
		},

		isHome() {
			return this.$route.name == 'home';
		},

		isLp() {
			switch (this.$route.name) {
				case 'novaLp':
					return true;
				case 'ginecologia':
					return true;
				case 'odontologia':
					return true;
				case 'oftalmologia':
					return true;
				case 'cardiologia':
					return true;
				case 'dermatologia':
					return true;
				case 'landingPage':
					return true;
				case 'neurologia':
					return true;
				case 'urologia':
					return true;
				case 'reumatologia':
					return true;
				case 'infectologia':
					return true;
				case 'ortopedia':
					return true;
				default:
					return false;
			}
		},

		end() {
			return this.$store.getters.unidade;
		},
		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 0;
				case 'sm':
					return 1;
				case 'md':
					return 2;
				case 'lg':
					return 3;
				case 'xl':
					return 4;
				default:
					return 0;
			}
		},
	},
};
</script>
