import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import AOS from "aos";
import "aos/dist/aos.css";
import VideoBackground from "vue-responsive-video-background-player";
import store from "./store";
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

Vue.directive("mask", VueMaskDirective);
Vue.component("video-background", VideoBackground);

new Vue({
	router,
	refreshOnceOnNavigation: true,
	vuetify,
	VueMeta,
	store,
	render: (h) => h(App),
	mounted() {
		AOS.init();
	},
}).$mount("#app");
