import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  state() {
    return {
      unidadeSelecionada: "",
      unidades: {
        sjc: {
          idName: "sjc",
          iframe:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.282612113482!2d-45.8905087!3d-23.1963691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4a6c89c5d705%3A0x23c8f0ccf4f34156!2sR.%20Maj.%20Francisco%20de%20Paula%20Elias%2C%20217%20-%20Vila%20Adyana%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012245-320!5e0!3m2!1spt-BR!2sbr!4v1660846333028!5m2!1spt-BR!2sbr",
          goggle:
            "https://www.google.com/maps/place/R.+Maj.+Francisco+de+Paula+Elias,+217+-+Vila+Adyana,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12245-320/@-23.1963642,-45.8926974,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a6c89c5d705:0x23c8f0ccf4f34156!8m2!3d-23.1963691!4d-45.8905087",
          waze: "https://www.waze.com/en/live-map/directions/br/sp/r.-maj.-francisco-de-paula-elias,-217?place=ChIJBdfFiWxKzJQRVkHz9MzwyCM",
          uber: "https://m.uber.com/ul/?action=setPickup&client_id=bI5vM53c_9VWV53J7EtgYi0XyM-i2pLI&pickup=my_location&dropoff[formatted_address]=R.%20Maj.%20Francisco%20de%20Paula%20Elias%2C%20217%20-%20Vila%20Adyana%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012245-320",
          insta: "https://www.instagram.com/proclinica.sjcampos/",
          face: "https://www.facebook.com/proclinica.sjcampos",
          id: "85",
          fachada: require("../assets/fachada.webp"),
          video: "https://abaremarketing.com/videoInstProclinica.mp4",
          telefone: "tel:+551230421832",
          textoTelefone: "(12) 3042-1832",
          form: "form_sjc",
          whats: " https://api.whatsapp.com/send?phone=551230421832",
          texto:
            "R. Maj. Francisco de Paula Elias, 217 - Vila Adyana, São José dos Campos - SP, 12245-320",
          logo: require("../assets/logo.webp"),
          logoBranco: require("../assets/prosjcbranco.webp"),
          logoPreto: require("../assets/logoSjcPreto.png"),
          logoCinza: require("../assets/logoCinzaSjc.png"),
          cidade: "SÃO JOSÉ DOS CAMPOS",
          idTag: "GTM-TNMM6Z9",
          //idFunil: "644150fd81b1be00155167c2",
          idFunilCampanha: "644150fd81b1be00155167c2",
          idFunilHome: "62fbe6c222e8e1000fbf5f3b",
          CRM: "Pró Clínica São José dos Campos CRM - 1007528/SP <br> Dr.Thiago Oliveira Belini RT CRM-203770/SP",
          CRO: "Pró Clínica São José dos Campos CRO - 026988/SP <br> Dr.Helberth Bicalho França RT CRO-150121/SP",
          carrossel: [
            require("../assets/carrosselSjc/1.webp"),
            require("../assets/carrosselSjc/2.webp"),
            require("../assets/carrosselSjc/3.webp"),
            require("../assets/carrosselSjc/4.webp"),
            require("../assets/carrosselSjc/5.webp"),
          ],
          dermatoHeroImg: require("../assets/lpsEspecialidade/dermatologia/heroImgSjc.png"),
          consultas: require("../assets/lpsEspecialidade/dermatologia/consultasSjc.svg"),
          dermatoSituacoes: require("../assets/lpsEspecialidade/dermatologia/situacoesSjc.png"),
          dermatoTratamento: require("../assets/lpsEspecialidade/dermatologia/tratamentoSjc.png"),
          neuroHeroImg: require("../assets/lpsEspecialidade/neuro/heroImgSjc.png"),
          neuroSituacoes: require("../assets/lpsEspecialidade/neuro/situacoesSjc.png"),
          neuroTratamento: require("../assets/lpsEspecialidade/neuro/tratamentoSjc.png"),
          neuroCheckUp: require("../assets/lpsEspecialidade/neuro/checkUpSjc.png"),
          uroloHeroImg: require("../assets/lpsEspecialidade/urologia/heroImgSjc.png"),
          uroloSituacoes: require("../assets/lpsEspecialidade/urologia/situacoesSjc.png"),
          uroloTratamento: require("../assets/lpsEspecialidade/urologia/tratamentoSjc.png"),
          Siteverification: "VpvcnT2pHrg14m6cZ7TE-aB7XSmo7nL-RJV5nI8woGU",
        },
        sorocaba: {
          idName: "sorocaba",
          iframe:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.2703492992364!2d-47.475206899999996!3d-23.486769900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c5f533bb266e9b%3A0x50c319b8f85df47e!2sAv.%20Santos%20Dumont%2C%20200%20-%20Jardim%20Ana%20Maria%2C%20Sorocaba%20-%20SP%2C%2018065-290!5e0!3m2!1spt-BR!2sbr!4v1660851304299!5m2!1spt-BR!2sbr",
          goggle:
            "https://www.google.com/maps/place/Av.+Santos+Dumont,+200+-+Jardim+Ana+Maria,+Sorocaba+-+SP,+18065-290/@-23.4867699,-47.4752069,17z/data=!3m1!4b1!4m5!3m4!1s0x94c5f533bb266e9b:0x50c319b8f85df47e!8m2!3d-23.4867699!4d-47.4752069",
          waze: "https://www.waze.com/en/live-map/directions/br/sp/av.-santos-dumont,-200?place=ChIJm24muzP1xZQRfvRd-LgZw1A",
          uber: "https://m.uber.com/ul/?action=setPickup&client_id=UXzh0Kof3ViJvAo4RSAkfBp_QtruEtwe&pickup=my_location&dropoff[formatted_address]=Av.%20Santos%20Dumont%2C%20200%20-%20Jardim%20Ana%20Maria%2C%20Sorocaba%20-%20SP%2C%2018065-290",
          whats: "https://api.whatsapp.com/send?phone=551530421188",
          insta: "https://www.instagram.com/proclinica.sorocaba/",
          face: "https://www.facebook.com/proclinica.sorocaba/",
          id: "86",
          fachada: require("../assets/fachadaSorocaba.webp"),
          video: "https://abaremarketing.com/proSorocaba.mp4",
          telefone: "tel:+551530421188",
          textoTelefone: "(15) 3042-1188",
          form: "form_sorocaba",
          texto:
            "Av. Santos Dumont, 200 - Jardim Ana Maria, Sorocaba - SP, 18065-290",
          cidade: "SOROCABA",
          logo: require("../assets/logoSorocaba.webp"),
          logoBranco: require("../assets/prosorobranco.webp"),
          logoPreto: require("../assets/logoSorocabaPreto.png"),
          logoCinza: require("../assets/logoCinzaSorocaba.png"),
          idTag: "GTM-MSBSWCJ",
          //idFunil: "644157b6973af2001734a813",
          idFunilCampanha: "644157b6973af2001734a813",
          idFunilHome: "6308cdf6cb3f67000f0eb48d",
          CRM: "Pró Clínica Sorocaba CRM - 994043/SP <br> Dr.Camila Larissa Torres do Espírito Santo RT CRM-223204/SP",
          CRO: "Pró Clínica Sorocaba CRO - 025304/SP <br> Dr.Mikem Muller Silveira Alves RT CRO-140613/SP",
          carrossel: [
            require("../assets/carrosselSoro/1.webp"),
            require("../assets/carrosselSoro/2.webp"),
            require("../assets/carrosselSoro/3.webp"),
            require("../assets/carrosselSoro/4.webp"),
            require("../assets/carrosselSoro/5.webp"),
            require("../assets/carrosselSoro/6.webp"),
            require("../assets/carrosselSoro/7.webp"),
          ],
          consultas: require("../assets/lpsEspecialidade/dermatologia/consultasSorocaba.svg"),
          dermatoHeroImg: require("../assets/lpsEspecialidade/dermatologia/heroImgSorocaba.png"),
          dermatoSituacoes: require("../assets/lpsEspecialidade/dermatologia/situacoesSorocaba.png"),
          dermatoTratamento: require("../assets/lpsEspecialidade/dermatologia/tratamentoSorocaba.png"),
          neuroHeroImg: require("../assets/lpsEspecialidade/neuro/heroImgSorocaba.png"),
          neuroSituacoes: require("../assets/lpsEspecialidade/neuro/situacoesSorocaba.png"),
          neuroTratamento: require("../assets/lpsEspecialidade/neuro/tratamentoSorocaba.png"),
          neuroCheckUp: require("../assets/lpsEspecialidade/neuro/checkUpSorocaba.png"),
          uroloHeroImg: require("../assets/lpsEspecialidade/urologia/heroImgSorocaba.png"),
          uroloSituacoes: require("../assets/lpsEspecialidade/urologia/situacoesSorocaba.png"),
          uroloTratamento: require("../assets/lpsEspecialidade/urologia/tratamentoSorocaba.png"),
          Siteverification: "JHBh_PkVNSMon6tJZ_1erWftP8EfWxaml-z0p-_OfOQ",
        },
      },
    };
  },
  getters: {
    unidade: (state) => {
      return state.unidadeSelecionada
        ? state.unidades[state.unidadeSelecionada]
        : {};
    },
  },
  mutations: {
    setUnidade(state, unidade) {
      state.unidadeSelecionada = unidade;
    },
  },
  actions: {
    setunidade({ commit, dispatch }) {
      let domain = window.location.host;
      if (domain.indexOf("proclinicasjc") > -1) {
        commit("setUnidade", "sjc");
        dispatch("enableGoogleAnalytics");
      } else {
        commit("setUnidade", "sorocaba");
        dispatch("enableGoogleAnalytics");
      }
    },
    async enableGoogleAnalytics({ dispatch }) {
      // var googleAnalyticsId = await loadGtmId(); // GTM-XXXXXXX
      dispatch("activateGtm");
    },
    activateGtm({ state }) {
      let w = window;
      let d = document;
      let s = "script";
      let l = "dataLayer";
      let i = state.unidades[state.unidadeSelecionada].idTag;
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
    async checkUserExists({ state }, user) {
      const response = await fetch(
        `https://gestao.abare.cloud/items/leads?filter[id_formulario]=${
          state.unidades[state.unidadeSelecionada].id
        }&fields=lead&limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const data = await response.json();
      return data.data.find((item) => item.lead.telefone === user.telefone);
    },
    enviarFormulario({ state }, user) {
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id_formulario: state.unidades[state.unidadeSelecionada].id,
          lead: user,
        }),
      })
        .then((response) => response.text())
        .then(() => {});
    },
    enviaRdstation({ state }, user) {
      return new Promise((resolve) => {
        fetch("https://abaregroup.com/phpSiteProClinica.php", {
          method: "POST",
          mode: "no-cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nome: user.nome,
            telefone: user.telefone,
            email: user.email,
            origemLead: user.origem.toUpperCase(),
            id_funil:
              user.origem === `campanha` ||
              user.origem === "odontologia" ||
              user.origem === "ginecologia" ||
              user.origem === "oftalmologia" ||
              user.origem === "cardiologia"
                ? state.unidades[state.unidadeSelecionada].idFunilCampanha
                : state.unidades[state.unidadeSelecionada].idFunilCampanha,
            // : state.unidades[state.unidadeSelecionada].idFunilHome,
          }),
        })
          .then((response) => response.text())
          .then(() => {
            resolve(true);
          });
      });
    },
  },
});

export default store;

